import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
@Injectable()
export class AppUtilityService {
    constructor(private snackBar: MatSnackBar) {
        /** Nothing to do */
    }

    public deepClone(clone): any {
        return JSON.parse(JSON.stringify(clone));
    }

    public toastMessage(message: string, action: string) {
        this.snackBar.open(message, action, {
          duration: 2000,
          panelClass: ['white-bg'],
          verticalPosition: 'top'
        });
    }

    formatDatetoUSAString(date) {
        return typeof date === 'string' ? date : ((date.getMonth() > 8) ?
            (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ?
                date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
    }

     formatString = (template, ...args) => {
      return template.replace(/{([0-9]+)}/g, function (match, index) {
        return typeof args[index] === 'undefined' ? match : args[index];
      });
    }

     formatMonthOfService(MOS:string){
      if(MOS !=="" && MOS !== null){
        let monthNumber =MOS.substring(0,2);
        var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return monthNames[Number(monthNumber)-1] +"-"+MOS.substring(2);
        }

    }
    getDateFromMOS(MOS:string)
    {
      if(MOS !=="" && MOS !== null){
        let monthNumber =Number(MOS.substring(0,2)) -1;
        let mosDate = new Date(Number(MOS.substring(2)),monthNumber,1);
        return mosDate;
    }
  }

}
