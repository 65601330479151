export const AdminConstants = {
    apiUrl: {
        getConfigTable: 'admin/campaign/search',
        getUserTable: 'users/load',
        saveUser: 'users/save',
        updateUser: 'users/update',
        userLov: 'users/master-details',
        userDetails: 'users/user-details',
        saveShow: 'admin/campaign',
        updateShow: 'admin/campaign/update',
        masterDataAdmin: 'admin/campaign/addNewCampaign',
        addSeries: 'admin/show/series',
        deleteSeries: 'admin/show/series/delete',
        addSeasonType: 'admin/show/seasonType',
        deleteSeasonType: 'admin/show/seasonType/delete',
        adminExport: '' , // update once endpoint is ready,
        rolesMasterData: 'roles/master-details',
        getRoleData: 'roles/role/entitlement',
        addRoleModuleEntitlements: 'roles/entitlement/save',
        updateRole: 'roles/entitlement/update',
        addRole: 'roles/role/save',
        getGenre: 'admin/genre/search',
        saveGenre: 'admin/genre/save',
        updateGenre: 'admin/genre/update', // update once endpoint is ready
        addVendor: 'admin/vendor/save',
        updateVendor: 'admin/vendor/update',
        getVendor: 'admin/vendor/search',
        getMediaType: 'admin/mediatype/search',
        mediaLov: 'admin/mediatype/lov',
        saveMediaType: 'admin/mediatype/save',
        updateMediaType: 'admin/mediatype/update',
        deleteMediaType: 'admin/mediatype/delete',
        getDistributionMediaTypes: 'admin/distribution/mediaTypes',
        distributionSearch: 'admin/distribution/search',
        saveDistribution: 'admin/distribution/save',
        updateDistribution: 'admin/distribution/update'
    }
};
