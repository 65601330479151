import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CommonGridComponent } from './components/common-grid/common-grid.component';
import { CommonCarouselGridComponent } from './components/common-carousel-grid/common-carousel-grid.component';
import { AdminModule } from '../media-management/admin/admin.module';
import { MultiInlineEditGridComponent } from './components/multi-inline-edit-grid/multi-inline-edit-grid.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkDetailRowDirective } from '../directive/cdk-detail-row.directive';
import { DragDropDirective } from '../directive/drag-drop-directive';
import { DisplaySelectboxAroundInputDirective } from '../directive/display-selectbox-around-input.directive';
import { MinusSignToParens } from '../pipe/minusSignToParens.pipe';
import { EllipsisPipe } from '../pipe/ellipsis.pipe';
import { AppMultiMonthPickerComponent } from './components/app-multi-month-picker/app-multi-month-picker.component';
import { AppMonthPickerComponent } from './components/app-month-picker/app-month-picker.component';
import { AppNotesComponent } from './components/app-notes/app-notes.component';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';
import { AppRowModifyComponent } from './components/app-row-modify/app-row-modify.component';
import { AppDynamicComponent } from './components/dynamic/app-dynamic.component';
import { ValidationsDisplayComponent } from './components/validations-display/validations-display.component'
import { HeaderLinksComponent } from './components/header-links/header-links.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AddPopupComponent } from './components/add-popup/add-popup.component';
import { AppInvoiceComponent } from './components/app-invoice/app-invoice.component';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { ValidationFormatterDirective } from '../directive/validation-formatter.directive';
import { AppEditEstimateComponent } from './components/app-edit-estimate/app-edit-estimate.component';
import { AmountFieldEditableComponent } from './components/amount-field-editable/amount-field-editable.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { EditTableComponentComponent } from './components/edit-table-component/edit-table-component.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppSpinnerComponent } from './components/app-spinner/app-spinner.component';

import { ExportExcelService } from '../services/exportExcel.service';
import { ChartModule } from 'angular-highcharts';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { EditableFieldComponent } from './components/editable-field/editable-field.component';
import { AppMOSComponent } from './components/app-mos-format/app-mos-format.component';
import { AppAutoCompleteComp } from './components/app-autocomplete/app-auto-complete.component';


@NgModule({
declarations: [DisplaySelectboxAroundInputDirective, CommonGridComponent, CommonCarouselGridComponent, AppHeaderComponent,
  CdkDetailRowDirective, DragDropDirective, MultiInlineEditGridComponent, MinusSignToParens, AppMultiMonthPickerComponent,
  AppMonthPickerComponent, AppNotesComponent, WarningDialogComponent, EditTableComponentComponent, AppRowModifyComponent,
  AppDynamicComponent, ValidationsDisplayComponent, HeaderLinksComponent, AppSpinnerComponent,AppMOSComponent,AppAutoCompleteComp, AddPopupComponent, AppInvoiceComponent,
  ValidationFormatterDirective, UploadFileComponent, AppEditEstimateComponent, AmountFieldEditableComponent,
  DonutChartComponent, EllipsisPipe, EditableFieldComponent
],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    UiSwitchModule,
    FontAwesomeModule,
    ChartModule
  ],
  exports: [
    DisplaySelectboxAroundInputDirective,
    CommonGridComponent,
    CommonCarouselGridComponent,
    MultiInlineEditGridComponent,
    AppHeaderComponent,
    RouterModule,
    AppMultiMonthPickerComponent,
    AppMonthPickerComponent,
    AppNotesComponent,
    EditTableComponentComponent,
    AppRowModifyComponent,
    AppDynamicComponent,
    ValidationsDisplayComponent,
    HeaderLinksComponent,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    UiSwitchModule,
    MaterialModule,
    AddPopupComponent,
    AppSpinnerComponent,
    AppMOSComponent,
    AppAutoCompleteComp,
    AppInvoiceComponent,
    WarningDialogComponent,
    ValidationFormatterDirective,
    UploadFileComponent,
    DonutChartComponent,
    MinusSignToParens,
    EditableFieldComponent
  ],
  providers: [ExportExcelService]
})
export class SharedModule { }
